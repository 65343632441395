import {
    ChangeDetectorRef,
    Directive,
    ElementRef,
    HostBinding,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import {
    UntilDestroy,
    untilDestroyed,
} from '@ngneat/until-destroy';
import {
    auditTime,
    fromEvent,
    Subscription,
} from 'rxjs';
import {
    distinctUntilChanged,
    filter,
    map,
    pairwise,
    share,
} from 'rxjs/operators';

export enum Direction {
    Up = 'Up',
    Down = 'Down'
}

@UntilDestroy()
@Directive({
    selector: '[ebScrollDetection]',
    exportAs: 'ebScrollDetection',
    standalone: true,
})
export class ScrollDetectionDirective implements OnChanges {
    @Input()
    public ebScrollDetectionLimit: number | null = null;

    @Input()
    @HostBinding('class.eb-scroll-enabled')
    public scrollDetectionEnabled = false;

    @HostBinding('class.eb-scroll-limit')
    public limitReached = false;

    private scroll$: Subscription | null = null;

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private cd: ChangeDetectorRef,
    ) {
    }


    ngOnChanges(changes: SimpleChanges): void {
        if (changes.scrollDetectionEnabled) {
            this.initStickyContainer();
        }
    }

    public initStickyContainer() {
        if (this.scroll$) {
            this.scroll$.unsubscribe();
        }

        this.scroll$ = fromEvent(this.elementRef.nativeElement, 'scroll')
            .pipe(
                auditTime(100),
                map((event) => {
                    if (!event) {
                        return {
                            y: 0,
                            scrollHeight: 0,
                        };
                    }
                    return {
                        y: (event.target as HTMLElement).scrollTop,
                        scrollHeight: (event.target as HTMLElement).scrollHeight,
                    };
                }),
                distinctUntilChanged(),
                pairwise(),
                filter(([prev, current]) => prev.scrollHeight === current.scrollHeight),
                map(([prev, current]): { direction: Direction, y: number, oldy: number } => ({
                    direction: (current.y < prev.y ? Direction.Up : Direction.Down),
                    y: current.y,
                    oldy: prev.y,
                })),
                share(),
                untilDestroyed(this),
            ).subscribe((scrollUpdate) => {
                if (scrollUpdate.direction === Direction.Down && (this.elementRef.nativeElement.scrollHeight - 200) > window.innerHeight) {
                    this.scrollDetectionEnabled = true;
                    if (this.ebScrollDetectionLimit) {
                        if (scrollUpdate.y > this.ebScrollDetectionLimit) {
                            this.limitReached = true;
                        } else {
                            this.limitReached = false;
                        }
                    }
                } else if (this.ebScrollDetectionLimit) {
                    this.scrollDetectionEnabled = false;
                    if (scrollUpdate.y < this.ebScrollDetectionLimit + 50) {
                        this.limitReached = false;
                        if (scrollUpdate.y < this.ebScrollDetectionLimit) {
                            this.scrollDetectionEnabled = false;
                        }
                    } else {
                        this.limitReached = true;
                    }
                } else if (scrollUpdate.direction === Direction.Up) {
                    this.scrollDetectionEnabled = false;
                }
                this.cd.detectChanges();
            });
    }
}
